import { Refine, Authenticated, type OAuthProvider } from "@refinedev/core";
import { ThemedLayoutV2 } from "./components/layout";
import { AntdInferencer } from "@refinedev/inferencer/antd";

import { useNotificationProvider } from "@refinedev/antd";
import "@refinedev/antd/dist/reset.css";

import routerBindings, {
  CatchAllNavigate,
  DocumentTitleHandler,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import { dataProvider } from "@refinedev/supabase";
import { dataProvider as blobStorageDataProvider } from "./blobStorageDataProvider";
import { App as AntdApp } from "antd";
import {
  Route,
  Outlet,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  ScrollRestoration,
} from "react-router-dom";
import authProvider from "./authProvider";
import { ColorModeContextProvider } from "./contexts/color-mode";
import { supabaseClient } from "./utility";

import { AssetListContainer } from "./pages/assets/AssetListContainer";
import { AssetList } from "./pages/assets/AssetList";
import { AssetShow } from "./pages/assets/AssetShow";
import { Dashboard } from "./pages/dashboard";
import { AuthPage } from "./components/pages/auth";
import { LoginTitle } from "./components/LoginTitle";
import {
  CodeSandboxOutlined,
  TagsOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { PropsWithChildren } from "react";
import { Gen3D } from "./pages/gen3d";
import { LabelReview } from "./pages/label_review/LabelReview";
import { Upload } from "./pages/upload";
import { UploadTerrain } from "./pages/terrain_uploader";
import { RequiresRole } from "./components/RequiresRole";

const Root: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <ColorModeContextProvider>
      <ScrollRestoration />
      <AntdApp>
        <Refine
          notificationProvider={useNotificationProvider}
          dataProvider={{
            default: dataProvider(supabaseClient),
            blobStorage: blobStorageDataProvider(),
          }}
          resources={[
            {
              meta: {
                label: "Processed",
                icon: <CodeSandboxOutlined />,
              },
              name: "data_processed",
              list: "/processed",
              show: "/processed/:id",
            },
            {
              name: "datasets",
              list: "/datasets",
              show: "/datasets/:id",
            },
            {
              name: "assets",
              list: "/assets",
              show: "/assets/:id",
            },
            {
              name: "upload",
              list: "/upload",
              meta: {
                label: "Upload World Data",
                icon: <UploadOutlined />,
              },
            },
            /*
            {
              name: "upload_terrain",
              list: "/upload_terrain",
              meta: {
                label: "Upload Terrain",
                icon: <UploadOutlined />,
              },
            },
            */
          ]}
          authProvider={authProvider}
          routerProvider={routerBindings}
          options={{
            syncWithLocation: true,
            warnWhenUnsavedChanges: true,
            useNewQueryKeys: true,
            projectId: "cRY9mf-68JWMC-KvoVe0",
            disableTelemetry: true,
          }}
        >
          <Outlet />
          <UnsavedChangesNotifier />
          <DocumentTitleHandler />
        </Refine>
      </AntdApp>
    </ColorModeContextProvider>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Root />}>
      <Route
        element={
          <Authenticated
            key="authn"
            loading={<div>loading...</div>}
            fallback={<CatchAllNavigate to="/login" />}
          >
            <ThemedLayoutV2>
              <Outlet />
            </ThemedLayoutV2>
          </Authenticated>
        }
      >
        <Route index element={<Dashboard />} />
        <Route
          element={
            <RequiresRole role="admin">
              <Outlet />
            </RequiresRole>
          }
        >
          <Route path="assets">
            <Route index element={<AssetList />} />
            <Route path=":id" element={<AssetShow />} />
          </Route>
          <Route path="processed" element={<AssetListContainer />} />
          <Route path="processed/:id" element={<AssetShow />} />
          <Route path="datasets">
            <Route index element={<AntdInferencer />} />
            <Route index path=":id" element={<AntdInferencer />} />
          </Route>
          <Route path="gen3d" element={<Gen3D />} />
        </Route>
        {/* end of RequiresRole="admin" */}
        <Route path="upload" element={<Upload />} />
        {/*
        <Route path="upload_terrain" element={<UploadTerrain />} />
        */}
      </Route>
      <Route
        path="/login"
        element={
          <AuthPage
            title={<LoginTitle />}
            registerLink={false}
            forgotPasswordLink={false}
            providers={[{ name: "google", label: "Sign In with Google" }]}
          />
        }
      />
    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;

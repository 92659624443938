import { useState, useEffect } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { message, Space, Upload as _Upload, Form, Input, Select, Button } from "antd";
import type { UploadProps } from "antd";
import { supabaseClient } from "../../utility/supabaseClient";
import { useGetIdentity } from "@refinedev/core";

const { Dragger } = _Upload;
const { TextArea } = Input;

interface MetadataForm {
  title: string;
  description: string;
  device: {
    model: string;
    name: string;
  };
  operator: string;
  location: {
    gps_tags: string[];
    address: string;
  };
  semantics: {
    scene_tags: string[];
    weather_tags: string[];
    lighting_tags: string[];
  };
}

// Validate directory name format: yyyy-mm-dd-hhmmss
function validateDirectoryName(name: string): boolean {
  const regex = /^\d{4}-\d{2}-\d{2}-\d{6}$/;
  return regex.test(name);
}

// Convert directory name to R2 path: yyyy/mm/dd/hhmmss
function getR2PathFromDirectoryName(dirName: string): string {
  // Input format: 2024-01-22-143000
  const [year, month, day, time] = dirName.split('-');
  return `${year}/${month}/${day}/${time}`;
}

async function checkDirectoryExists(dirPath: string): Promise<boolean> {
  try {
    const { data, error } = await supabaseClient.functions.invoke('check-r2-directory', {
      body: { dirPath },
    });

    if (error) throw error;
    return data.exists;
  } catch (error) {
    console.error('Error checking directory:', error);
    throw error;
  }
}

async function getPresignedUrl(filename: string, contentType: string): Promise<string> {
  const { data, error } = await supabaseClient.functions.invoke('generate-r2-presigned-url', {
    body: { filename, contentType },
  });

  if (error) {
    console.error('Error getting presigned URL:', error);
    throw error;
  }
  if (!data?.presignedUrl) {
    throw new Error('No presigned URL returned');
  }
  return data.presignedUrl;
}

// Add retry utilities at the top of the file after imports
const MAX_RETRIES = 3;
const INITIAL_RETRY_DELAY = 1000; // 1 second
const MAX_CONCURRENT_UPLOADS = 2;

async function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function withRetry<T>(
  operation: () => Promise<T>,
  retryCount = 0
): Promise<T> {
  try {
    return await operation();
  } catch (error) {
    if (retryCount >= MAX_RETRIES) {
      throw error;
    }
    // Exponential backoff with jitter
    const delay = INITIAL_RETRY_DELAY * Math.pow(2, retryCount) * (0.5 + Math.random());
    await sleep(delay);
    return withRetry(operation, retryCount + 1);
  }
}

// Modify the uploadToR2 function to include retries
async function uploadToR2(presignedUrl: string, file: File, onProgress?: (percent: number) => void): Promise<void> {
  return withRetry(async () => {
    try {
      const xhr = new XMLHttpRequest();
      
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable && onProgress) {
          const percent = Math.round((event.loaded / event.total) * 100);
          onProgress(percent);
        }
      };

      return new Promise<void>((resolve, reject) => {
        xhr.open('PUT', presignedUrl);
        xhr.setRequestHeader('Content-Type', file.type || 'application/octet-stream');

        xhr.onload = () => {
          if (xhr.status >= 200 && xhr.status < 300) {
            resolve();
          } else {
            reject(new Error(`Upload failed: ${xhr.statusText}`));
          }
        };

        xhr.onerror = () => {
          reject(new Error('Network error during upload'));
        };

        xhr.send(file);
      });
    } catch (error) {
      console.error('Upload attempt failed:', error);
      throw error;
    }
  });
}

async function uploadMetadataJson(dirPath: string, metadata: MetadataForm): Promise<void> {
  const jsonContent = JSON.stringify(metadata, null, 2);
  const blob = new Blob([jsonContent], { type: 'application/json' });
  const file = new File([blob], 'metadata.json', { type: 'application/json' });

  const presignedUrl = await getPresignedUrl(
    `${dirPath}/metadata.json`,
    'application/json'
  );

  await uploadToR2(presignedUrl, file);
}

// Constants for multipart upload
const CHUNK_SIZE = 100 * 1024 * 1024; // 100MB in bytes
const MAX_CONCURRENT_CHUNKS = 5;

// Modify the uploadLargeFile function to include retries for each chunk
async function uploadLargeFile(file: File, r2Path: string, onProgress?: (percent: number) => void): Promise<void> {
  let uploadId: string | undefined;
  try {
    // Initiate multipart upload
    const { data: initData, error: initError } = await supabaseClient.functions.invoke('multipart-upload', {
      body: { 
        action: 'initiate',
        filename: r2Path,
        contentType: file.type || 'application/octet-stream'
      },
    });

    if (initError) throw initError;
    uploadId = initData.uploadId;

    // Calculate total chunks
    const chunks = Math.ceil(file.size / CHUNK_SIZE);
    const parts: { PartNumber: number; ETag: string }[] = [];
    let uploadedBytes = 0;

    // Process chunks in batches
    for (let startChunk = 1; startChunk <= chunks; startChunk += MAX_CONCURRENT_CHUNKS) {
      const chunkPromises = [];
      const endChunk = Math.min(startChunk + MAX_CONCURRENT_CHUNKS - 1, chunks);

      for (let partNumber = startChunk; partNumber <= endChunk; partNumber++) {
        const start = (partNumber - 1) * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, file.size);
        const chunk = file.slice(start, end);

        chunkPromises.push(
          (async () => {
            return withRetry(async () => {
              // Get presigned URL for this part
              const { data: urlData, error: urlError } = await supabaseClient.functions.invoke('multipart-upload', {
                body: { 
                  action: 'getPartUrl',
                  filename: r2Path,
                  uploadId,
                  partNumber,
                },
              });
              if (urlError) throw urlError;

              // Upload the chunk with retry
              const response = await fetch(urlData.presignedUrl, {
                method: 'PUT',
                body: chunk,
              });

              if (!response.ok) {
                throw new Error(`Failed to upload part ${partNumber}: ${response.statusText}`);
              }

              const etag = response.headers.get('ETag');
              if (!etag) {
                throw new Error(`No ETag received for part ${partNumber}`);
              }

              uploadedBytes += chunk.size;
              if (onProgress) {
                onProgress((uploadedBytes / file.size) * 100);
              }

              return {
                PartNumber: partNumber,
                ETag: etag.replace(/['"]/g, ''), // Remove quotes from ETag
              };
            });
          })()
        );
      }

      // Wait for current batch to complete
      const results = await Promise.all(chunkPromises);
      parts.push(...results);
    }

    // Complete multipart upload with retry
    await withRetry(async () => {
      const { error: completeError } = await supabaseClient.functions.invoke('multipart-upload', {
        body: { 
          action: 'complete',
          filename: r2Path,
          uploadId,
          parts: parts.sort((a, b) => a.PartNumber - b.PartNumber),
        },
      });
      if (completeError) throw completeError;
    });

  } catch (error) {
    console.error('Multipart upload error:', error);
    // Attempt to abort the multipart upload if we have an uploadId
    if (uploadId) {
      try {
        await supabaseClient.functions.invoke('multipart-upload', {
          body: { 
            action: 'abort',
            filename: r2Path,
            uploadId,
          },
        });
      } catch (abortError) {
        console.error('Failed to abort multipart upload:', abortError);
      }
    }
    throw error;
  }
}

// Add this interface near the top with other interfaces
interface QueuedFile {
  file: File;
  objectUrl?: string;
}

// Update the customRequest function to handle large files
const makeProps = (
  metadata: MetadataForm | null,
  setUploadingFiles: React.Dispatch<React.SetStateAction<Set<string>>>,
  setHasUploaded: React.Dispatch<React.SetStateAction<boolean>>,
  setUploadQueue: React.Dispatch<React.SetStateAction<QueuedFile[]>>,
  uploadQueue: QueuedFile[],
  uploadingFiles: Set<string>,
  cleanupFile: (queuedFile: QueuedFile) => void,
  checkedDirectories: Set<string>,
  setCheckedDirectories: React.Dispatch<React.SetStateAction<Set<string>>>
): UploadProps => {
  return {
    name: "directory",
    multiple: false,
    directory: true,
    customRequest: async ({ file, onSuccess, onError, onProgress }) => {
      const cFile = file as File;
      const queuedFile: QueuedFile = { file: cFile };

      try {
        // Get directory name from the file path
        const dirPath = cFile.webkitRelativePath;
        const dirName = dirPath.split('/')[0];

        // Validate directory name format
        if (!validateDirectoryName(dirName)) {
          throw new Error('Directory name must be in the format: yyyy-mm-dd-hhmmss');
        }

        // Get R2 path from directory name
        const r2BasePath = getR2PathFromDirectoryName(dirName);
        const r2DirPath = `capture/${r2BasePath}`;

        // Check directory existence only once per directory
        if (!checkedDirectories.has(r2DirPath)) {
          const exists = await checkDirectoryExists(r2DirPath);
          if (exists) {
            throw new Error(`Directory ${dirName} already exists in R2`);
          }
          setCheckedDirectories(prev => new Set(prev).add(r2DirPath));
        }

        // If we're already at max concurrent uploads, add to queue
        if (uploadingFiles.size >= MAX_CONCURRENT_UPLOADS) {
          setUploadQueue(prev => [...prev, queuedFile]);
          return;
        }

        // Add file to tracking set
        setUploadingFiles((prev: Set<string>) => {
          const newSet = new Set(prev);
          newSet.add(cFile.name);
          return newSet;
        });

        // For the first file in the directory, upload the metadata JSON
        if (metadata && cFile.webkitRelativePath.split('/').length === 2) {
          await uploadMetadataJson(r2DirPath, metadata);
        }
        
        // Get the file's relative path within the directory
        const fileRelativePath = cFile.webkitRelativePath.split('/').slice(1).join('/');
        
        // Combine R2 base path with file's relative path
        const r2FullPath = `${r2DirPath}/${fileRelativePath}`;

        console.log('Processing file:', {
          name: cFile.name,
          dirName,
          r2Path: r2FullPath,
          type: cFile.type,
          size: cFile.size,
        });

        if (cFile.size > 1024 * 1024 * 1024) {  // 1GB
          await uploadLargeFile(cFile, r2FullPath, (percent) => {
            if (onProgress) {
              onProgress({ percent });
            }
          });
        } else {
          const presignedUrl = await getPresignedUrl(
            r2FullPath,
            cFile.type || 'application/octet-stream'
          );

          await uploadToR2(presignedUrl, cFile, (percent) => {
            if (onProgress) {
              onProgress({ percent });
            }
          });
        }

        if (onSuccess) {
          onSuccess("Upload successful");
        }

        // Clean up the file data
        cleanupFile(queuedFile);

        // Remove file from tracking set after successful upload
        setUploadingFiles((prev: Set<string>) => {
          const newSet = new Set(prev);
          newSet.delete(cFile.name);
          if (newSet.size === 0 && uploadQueue.length === 0) {
            setHasUploaded(true);
          }
          return newSet;
        });

      } catch (error) {
        // Clean up on error
        cleanupFile(queuedFile);
        
        // Remove file from tracking on error
        setUploadingFiles((prev: Set<string>) => {
          const newSet = new Set(prev);
          newSet.delete(cFile.name);
          return newSet;
        });
        console.error('Upload process error:', error);
        if (onError) {
          onError(error as any);
        }
        message.error(error instanceof Error ? error.message : 'Upload failed');
      }
    },
    onChange(info) {
      const { status } = info.file;
      if (status === "uploading") {
        console.log('Uploading:', info.file.name);
      } else if (status === "done") {
        message.success(`${info.file.name} uploaded successfully`);
      } else if (status === "error") {
        message.error(`${info.file.name} upload failed`);
      }
    },
    onDrop(e) {
      const files = Array.from(e.dataTransfer.files);
      if (files.length > 0) {
        const dirName = (files[0] as any).webkitRelativePath.split('/')[0];
        if (!validateDirectoryName(dirName)) {
          message.error('Directory name must be in the format: yyyy-mm-dd-hhmmss');
          e.preventDefault();
          return;
        }
      }
    },
    showUploadList: true,
    accept: "*/*",
  };
};

// Add this validation function near the top with other utility functions
function validateGPSCoordinate(coord: string): boolean {
  // Format: latitude,longitude (e.g., "37.7749,-122.4194")
  const regex = /^-?\d+\.?\d*,-?\d+\.?\d*$/;
  if (!regex.test(coord)) return false;

  const [lat, lng] = coord.split(',').map(Number);
  return (
    !isNaN(lat) && 
    !isNaN(lng) && 
    lat >= -90 && 
    lat <= 90 && 
    lng >= -180 && 
    lng <= 180
  );
}

export const Upload: React.FC = () => {
  const [form] = Form.useForm();
  const [showUploader, setShowUploader] = useState(false);
  const [metadata, setMetadata] = useState<MetadataForm | null>(null);
  const [hasUploaded, setHasUploaded] = useState(false);
  const [uploadingFiles, setUploadingFiles] = useState<Set<string>>(new Set());
  const [uploadQueue, setUploadQueue] = useState<QueuedFile[]>([]);
  const [isProcessingQueue, setIsProcessingQueue] = useState(false);
  const [checkedDirectories, setCheckedDirectories] = useState<Set<string>>(new Set());
  const { data: user } = useGetIdentity<{ email: string }>();

  // Clean up function to release memory
  const cleanupFile = (queuedFile: QueuedFile) => {
    if (queuedFile.objectUrl) {
      URL.revokeObjectURL(queuedFile.objectUrl);
    }
    // Explicitly remove references to help garbage collection
    queuedFile.file = null as any;
    queuedFile.objectUrl = undefined;
  };

  // Process the upload queue
  const processQueue = async () => {
    if (isProcessingQueue) return;
    setIsProcessingQueue(true);

    while (uploadQueue.length > 0 && uploadingFiles.size < MAX_CONCURRENT_UPLOADS) {
      const nextQueuedFile = uploadQueue[0];
      setUploadQueue(prev => prev.slice(1));
      
      // Start the upload for this file
      const customRequest = makeProps(metadata, setUploadingFiles, setHasUploaded, setUploadQueue, uploadQueue, uploadingFiles, cleanupFile, checkedDirectories, setCheckedDirectories).customRequest;
      if (customRequest) {
        customRequest({
          file: nextQueuedFile.file,
          onSuccess: () => {
            cleanupFile(nextQueuedFile);
          },
          onError: () => {
            cleanupFile(nextQueuedFile);
          },
          onProgress: () => {},
          filename: nextQueuedFile.file.name,
          action: '',
          method: 'PUT',
          data: {}
        });
      }
    }

    setIsProcessingQueue(false);
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      // Clean up any remaining files in queue
      uploadQueue.forEach(cleanupFile);
    };
  }, []);

  // Watch for changes in the queue and uploading files
  useEffect(() => {
    if (uploadQueue.length > 0 && uploadingFiles.size < MAX_CONCURRENT_UPLOADS) {
      processQueue();
    }
  }, [uploadQueue, uploadingFiles]);

  const props = makeProps(
    metadata, 
    setUploadingFiles, 
    setHasUploaded, 
    setUploadQueue, 
    uploadQueue, 
    uploadingFiles, 
    cleanupFile,
    checkedDirectories,
    setCheckedDirectories
  );

  const formatTag = (tag: string): string => {
    return tag.toLowerCase().replace(/\s+/g, '_');
  };

  const onFinish = (values: MetadataForm) => {
    // Format all tags
    const formattedValues = {
      ...values,
      operator: user?.email || 'unknown',
      semantics: {
        scene_tags: values.semantics.scene_tags.map(formatTag),
        weather_tags: values.semantics.weather_tags.map(formatTag),
        lighting_tags: values.semantics.lighting_tags.map(formatTag),
      }
    };

    setMetadata(formattedValues);
    setShowUploader(true);
  };

  const resetForm = () => {
    form.resetFields();
    setShowUploader(false);
    setMetadata(null);
    setHasUploaded(false);
  };

  if (!showUploader) {
    return (
      <>
        <h1>World Data Capture</h1>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            device: { model: undefined },
            semantics: {
              scene_tags: [],
              weather_tags: [],
              lighting_tags: []
            }
          }}
        >
          <Form.Item
            label="Title"
            name="title"
            required
            rules={[{ required: true, message: 'Please enter a title' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            required
            rules={[{ required: true, message: 'Please enter a description' }]}
          >
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item label="Device" required>
            <Form.Item
              name={['device', 'model']}
              rules={[{ required: true, message: 'Please select a device model' }]}
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
            >
              <Select
                placeholder="Select Device Model"
                options={[
                  { label: 'L2', value: 'L2' },
                  { label: 'L2Pro', value: 'L2Pro' },
                  { label: 'Kitty', value: 'Kitty' },
                  { label: 'XplorV1', value: 'XplorV1' },
                  { label: 'XplorV2', value: 'XplorV2' },
                ]}
              />
            </Form.Item>
            <Form.Item
              name={['device', 'name']}
              rules={[{ required: true, message: 'Please enter device name' }]}
              style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
            >
              <Input placeholder="Device Name" />
            </Form.Item>
          </Form.Item>

          <Form.Item label="Location" required>
            <Form.Item
              name={['location', 'gps_tags']}
              rules={[{ 
                required: true,
                validator: async (_, values) => {
                  if (!values || values.length === 0) {
                    throw new Error('Please enter at least one GPS coordinate');
                  }
                  const invalidCoords = values.filter((coord: string) => !validateGPSCoordinate(coord));
                  if (invalidCoords.length > 0) {
                    throw new Error('GPS coordinates must be in format: latitude,longitude (e.g., "37.7749,-122.4194")');
                  }
                }
              }]}
            >
              <Select
                mode="tags"
                placeholder="Enter GPS Coordinates (latitude,longitude)"
                style={{ width: '100%' }}
                tokenSeparators={[' ']}
                notFoundContent={null}
              />
            </Form.Item>
            <Form.Item
              name={['location', 'address']}
              rules={[{ required: true, message: 'Please enter the address' }]}
            >
              <Input placeholder="Address" />
            </Form.Item>
          </Form.Item>

          <Form.Item label="Semantics" required>
            <Form.Item
              name={['semantics', 'scene_tags']}
              rules={[{ required: true }]}
            >
              <Select
                mode="tags"
                placeholder="Scene Tags"
                style={{ width: '100%' }}
                options={[
                  // Environment
                  { label: 'Outdoor', value: 'outdoor' },
                  { label: 'Indoor', value: 'indoor' },
                  { label: 'Nature', value: 'nature' },
                  
                  // Natural Features
                  { label: 'Lake', value: 'lake' },
                  { label: 'Mountain', value: 'mountain' },
                  { label: 'Forest', value: 'forest' },
                  { label: 'Trees', value: 'trees' },
                  { label: 'Flowers', value: 'flowers' },
                  { label: 'Rocks', value: 'rocks' },
                  { label: 'Beach', value: 'beach' },
                  { label: 'Cave', value: 'cave' },
                  
                  // Urban Features
                  { label: 'Plaza', value: 'plaza' },
                  { label: 'Statue', value: 'statue' },
                  { label: 'Monument', value: 'monument' },
                  { label: 'Parking', value: 'parking' },
                  { label: 'Playground', value: 'playground' },
                  { label: 'Residential', value: 'residential' },
                  { label: 'Trail', value: 'trail' },
                  { label: 'Business', value: 'business' },
                  { label: 'Shopping Center', value: 'shopping_center' },
                  { label: 'Restaurant', value: 'restaurant' },
                  { label: 'Temple', value: 'temple' },
                  { label: 'Castle', value: 'castle' },
                  { label: 'Building', value: 'building' },
                  { label: 'Tunnel', value: 'tunnel' },
                  { label: 'Furniture', value: 'furniture' },
                  
                  // Sports & Recreation
                  { label: 'Basketball Court', value: 'basketball_court' },
                  { label: 'Soccer Field', value: 'soccer_field' },
                  { label: 'Tennis Court', value: 'tennis_court' },
                  { label: 'Pool', value: 'pool' },
                  { label: 'Garden', value: 'garden' },
                  { label: 'Fitness Center', value: 'fitness_center' },
                  
                  // Transportation
                  { label: 'Transportation', value: 'transportation' },
                  { label: 'Cars', value: 'cars' },
                  { label: 'Bike', value: 'bike' },
                  
                  // Time of Day
                  { label: 'Sunrise', value: 'sunrise' },
                  { label: 'Morning', value: 'morning' },
                  { label: 'Long Shadow', value: 'long_shadow' },
                  { label: 'Noon', value: 'noon' },
                  { label: 'Afternoon', value: 'afternoon' },
                  { label: 'Sunset', value: 'sunset' },
                  
                  // People
                  { label: 'Crowd', value: 'crowd' },
                ]}
              />
            </Form.Item>
            <Form.Item
              name={['semantics', 'weather_tags']}
              rules={[{ required: true }]}
            >
              <Select
                mode="tags"
                placeholder="Weather Tags"
                style={{ width: '100%' }}
                options={[
                  { label: 'Sunny', value: 'sunny' },
                  { label: 'Cloudy', value: 'cloudy' },
                  { label: 'Overcast', value: 'overcast' },
                  { label: 'Snow', value: 'snow' },
                  { label: 'After Rain', value: 'after_rain' },
                  { label: 'Calm', value: 'calm' },
                  { label: 'Windy', value: 'windy' },
                  { label: 'Clear Sky', value: 'clear_sky' },
                ]}
              />
            </Form.Item>
            <Form.Item
              name={['semantics', 'lighting_tags']}
              rules={[{ required: true }]}
            >
              <Select
                mode="tags"
                placeholder="Lighting Tags"
                style={{ width: '100%' }}
                options={[
                  { label: 'Natural', value: 'natural' },
                  { label: 'Artificial', value: 'artificial' },
                  { label: 'Abundant', value: 'abundant' },
                  { label: 'Shady', value: 'shady' },
                  { label: 'Reflections', value: 'reflections' },
                  { label: 'Dramatic Change', value: 'dramatic_change' },
                  { label: 'Dark', value: 'dark' },
                ]}
              />
            </Form.Item>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Next: Upload Files
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  }

  return (
    <>
      <h1>Upload World Data</h1>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Space>
          <Button onClick={() => setShowUploader(false)} style={{ marginBottom: 16 }}>
            Back to Questionnaire
          </Button>
          <Button type="primary" onClick={resetForm} style={{ marginBottom: 16 }}>
            Done
          </Button>
        </Space>
        <Dragger {...props} disabled={hasUploaded}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            {hasUploaded 
              ? "Directory uploaded successfully. Click 'Done' to start a new upload."
              : "Click or drag a directory to this area to upload"
            }
          </p>
          {!hasUploaded && (
            <p className="ant-upload-hint">
              Directory name must be in the format: yyyy-mm-dd-hhmmss<br />
              Example: 2024-01-22-143000
            </p>
          )}
        </Dragger>
      </Space>
    </>
  );
};
